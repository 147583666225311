import React from 'react';
import { Button } from './Button';
import './Services.css';
import { SiMicrosoftoffice } from 'react-icons/si';
import { SiMicrosoftsqlserver } from 'react-icons/si';
import { SiPowerbi } from 'react-icons/si';
import { SiDynamics365 } from 'react-icons/si';
import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <IconContext.Provider value={{ color: '#fff', size: 64 }}>
      <div className='pricing__section'>
        <div className='pricing__wrapper'>
          <h1 className='pricing__heading'>Services</h1>
          <div className='pricing__container'>
            <Link to='/sign-up' className='pricing__container-card'>
              <div className='pricing__container-cardInfo'>
                <div className='icon'>
                  <SiMicrosoftoffice />
                </div>
                <h4>Microsoft</h4>
                <h4>365</h4>
                <h3>SharePoint</h3>
                <p>Microsoft Teams</p>
                <ul className='pricing__container-features'>
                  <li>Oulook</li>
                  <li>OneDrive</li>
                  
                </ul>
                <Button buttonSize='btn--wide' buttonColor='primary'>
                  Microsoft 365
                </Button>
              </div>
            </Link>
            <Link to='/sign-up' className='pricing__container-card'>
              <div className='pricing__container-cardInfo'>
                <div className='icon'>
                <SiPowerbi />
                </div>             
                <h4>Power</h4>
                <h4>Platform</h4>
                <h3>Canvas App</h3>
                <h3>Model Driven App</h3>
                <h3>Power Page</h3>
                <ul className='pricing__container-features'>
                  <li>Power Automate</li>
                  <li>Power BI</li>
              
                </ul>
                <Button buttonSize='btn--wide' buttonColor='blue'>
                  Power Platform
                </Button>
              </div>
            </Link>
            <Link to='/sign-up' className='pricing__container-card'>
              <div className='pricing__container-cardInfo'>
                <div className='icon'>
                  <SiDynamics365 />
                </div>
                <h4>Dynamics</h4>
                <h4>365</h4>
                <h3>Dynamics 365 Sales</h3>
                <h3>Customer Service</h3>
                <ul className='pricing__container-features'>
                  <li>Migration from Dynamics CRM to Dynamics 365</li>
                  <li>Dynamics 365 Accelator</li>
                  
                </ul>
                <Button buttonSize='btn--wide' buttonColor='primary'>
                  Dynamics 365
                </Button>
              </div>
            </Link>
            <Link to='/sign-up' className='pricing__container-card'>
              <div className='pricing__container-cardInfo'>
                <div className='icon'>
                  <SiMicrosoftsqlserver/>
                </div>
                <h4>Azure</h4>
                <h3>Azure Computing</h3>
                <h3>Azure Storage</h3>
                <ul className='pricing__container-features'>
                  <li>Azure Health</li>
                  <li>Azure Infrastructure</li>
                  
                </ul>
                <Button buttonSize='btn--wide' buttonColor='blue'>
                  Azure
                </Button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
}
export default Services;