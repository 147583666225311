  export const homeObjFour = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'About US',
    headline: 'Mission & Vision',
    description:'Long experience in Australia to provide IT services. We Provide solution with Microsoft certified professionals to serve our customers. We look forward to your call. Just let us know your any requirements whether minor or large.',
    buttonLabel: 'Sign Up Now',
    imgStart: '',
    img: 'images/24.png',
    alt: 'Vault'
  };