import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/dy.png'
              text='Dynamic 365'
              label='Adventure'
              path=''
            />
            <CardItem
              src='images/pplat.jpg'
              text='Microsoft Power Platform'
              label='Luxury'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/Technology.jpg'
              text='Contact us for IT support'
              label='Mystery'
            />
            <CardItem
              src='images/office.jpg'
              text='Microsoft Office-365 softwares'
              label='Adventure'
            />
            <CardItem
              src='images/IT-services.jpg'
              text='IT experts are there you 24/7'
              label='Adrenaline'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
