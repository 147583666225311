export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Srvices',
    headline: 'One stop solution centre',
    description:
      'We provide cost-effective solution for server setup, printer setup, internet setup,  company domain registration, web design, storage and backup solution, antivirus soluition, cybersecurity solution, software development, monitoring service, business intelligence etc. Provide best quotation for any hardware equipment with setup like desktop, laptop, server, video conference, smartboard etc.',
    buttonLabel: 'Contact Us',
    imgStart: 'start',
    img: 'images/two.png',
    alt: 'Credit Card'
  };
