import React from 'react';
import HeroSection from '../../HeroSection';
import {homeObjOne,homeObjTwo,
    homeObjThree,homeObjFour} from './Data';
import Cards from '../../Cards';
import Services from '../../Services';

function Home() {
    return (
        <>
            <HeroSection {...homeObjOne} />           
            <HeroSection {...homeObjThree} />
            <Cards />
            <HeroSection {...homeObjTwo} />
            <Services />
            <HeroSection {...homeObjFour} />
            
        </>
    );
}

export default Home
