import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/HomePage/Home';
import Services from './components/pages/Services/Services';
import About from './components/pages/About/About';
import Form from './components/pages/Form/Form';
import SignUp from './components/pages/SignUp/SignUp';
import Footer from './components/pages/Footer/Footer';

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home}/>
        <Route path='/services' component={Services}/>
        <Route path='/about' component={About}/>
        <Route path='/form' component={Form}/>
        <Route path='/sign-up' component={SignUp}/>
      </Switch>
      <Footer/>
    </Router>
  );
}

export default App;
