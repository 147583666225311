export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'IT Services',
    headline: 'Accelerate digital transformation',
    description: 'Welcome to Triple-P Solution provider. Your all business solutions in one place from Web-site and Backend to CRM and ERP Solution',
    buttonLabel: 'Get Started',
    imgStart: '',
    img: 'images/circle.png',
    alt: 'tech'
};

export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Microsoft 365, Dynamics 365 and Azure',
    headline: 'Super fast and simple onboarding process',
    description: 'For any organization Internal CRM acitvities, Reporting or Power Portal or Web site related task, we provides best soluton for all your office IT needs. We can setup or migrate data from different data sources with satisfaction. ',
    buttonLabel: 'Start Now',
    imgStart: 'Start',
    img: 'images/PngItem.png',
    alt: 'Vault'
};

export const homeObjTwo = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '100% SECURE',
    headline: 'Stay protected 24/7 anywhere anytime with Azure Cloud',
    description: 'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access with Azure and other SAAS Application',
    buttonLabel: 'Learn More',
    imgStart: 'Start',
    img: 'images/power p.png',
    alt: 'Vault'
};


export const homeObjFour = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'DATA ANALYTICS',
    headline: 'Every transaction is stored on our secure cloud database',
    description: 'Never ever have to worry again about saved recipets. We store your data, so you can access it anytime ',
    buttonLabel: 'Sign Up Now',
    imgStart: 'Start',
    img: 'images/Microsoft-Dynamics-365.png',
    alt: 'Vault'
};